import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComparisonTable from '../components/comparisonTable'

const Container = styled.div`
  background-image: linear-gradient(black, #686868);
  padding-bottom: 20px;
`


const PageTitle = styled.h1`
    margin: auto 0;
    padding-top: 0.5em;
    color: #c8c7c5;
`

const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const SecondPage = () => (
  <Layout>
    <SEO title="Features" />
    <Container>
      <PageHeader>
          <PageTitle>Features</PageTitle>
      </PageHeader>
      <ComparisonTable/>

    </Container>
  </Layout>
)

export default SecondPage
