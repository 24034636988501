import React from "react"
import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"

const Table = styled.table`
  max-width: 90%;
  margin: auto auto;
  color: #1f1f1f;
  margin-top: 20px;
  text-align: center;
  border-collapse: collapse;

  thead {
    margin-bottom: 10px;
  }

  th {
    background-color: #dd2222;
    color: #f1f1f1;
    max-width: 80%;
    padding: 5px;
  }

  tr:nth-child(odd) td {
    background-color:#f1f1f1;
  }
  tr:nth-child(even) td {
    background-color: #fff;
  }

  td {
    color: #3c3c3c;
    padding: 3px 0;
  }

`
const renderRaw = (content) => {
  return {__html: content}; 
}

const TableRow = ({items}) => (
  <tr>
    {
      items.map(
        (column, index) => (
          <td key={index} dangerouslySetInnerHTML={renderRaw(column.replace(/\\n/g, '<br/>'))} />
        )
      )
    }
  </tr>
)

const TableHeader = ({items}) => (
  

  <tr>
    {
      items.map(
        (column, index) => (
          /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
          <th key={index} dangerouslySetInnerHTML={renderRaw(column.replace(/\\n/g, '<br/>'))}/>
        )
      )
    }
  </tr>
)

const NUMBER_OF_HEADERS = 1;

const ComparisonTable = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          siteInfo {
            content
          }

        }
      `}
      render={data => {
        const features = JSON.parse(data.siteInfo.content)

        return (
          <Table>
            <thead>
              {
                features.slice(0, NUMBER_OF_HEADERS).map(
                  (headers, index) => <TableHeader key={index} items={headers} />
                )
              }
            </thead>
            <tbody>
              {
                features.slice(NUMBER_OF_HEADERS).map(
                  (feat, index) => <TableRow key={index} items={feat} />
                )
              }
            </tbody>
          </Table>

        )}

      }
    />
  )
}

export default ComparisonTable